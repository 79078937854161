import { get } from "lodash";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

export const createReducer =
  (initialState: any, reducerMap: any) =>
  (state = initialState, action: any) => {
    const reducer = reducerMap[action.type];
    if (typeof reducer !== "function") {
      return state;
    }
    return reducer(state, action);
  };

const getValueRedux = (path: string) => (object: any) => get(object, path);
const selectValueRedux = (path: string) => createSelector(getValueRedux(path), (data) => data);
const getSelector = (path: string) => useSelector(selectValueRedux(path));
export const getRedux = (path: string, defaultValue: any) => getSelector(path) || defaultValue;
