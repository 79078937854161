import { SyncOutlined } from "@ant-design/icons";
import G6, { EdgeConfig, GraphOptions, NodeConfig } from "@antv/g6";
import { ContainersActive, ContainersInactive } from "components/svgs/Containers";
import ShipmentBooked from "components/svgs/ShipmentBooked";
import ShipmentCommitted from "components/svgs/ShipmentCommitted";
import ShipmentCreated from "components/svgs/ShipmentCreated";
import ShipmentCreatedNoSquare from "components/svgs/ShipmentCreatedNoSquare";
import ShipmentDelivered from "components/svgs/ShipmentDelivered";
import ShipmentDeliveredNoSquare from "components/svgs/ShipmentDeliveredNoSquare";
import ShipmentEstDelivered from "components/svgs/ShipmentEstDelivered";
import ShipmentInTransit from "components/svgs/ShipmentInTransit";
import ShipmentIntransitNoSquare from "components/svgs/ShipmentIntransitNoSquare";
import ShipmentReceived from "components/svgs/ShipmentReceived";
import { ShipV2Active, ShipV2Inactive } from "components/svgs/ShipV2";
import { TagThemeProps } from "components/Tag/Tag";
import cryptoJs from "crypto-js";
import { format, getTimezoneOffset, utcToZonedTime } from "date-fns-tz";
import useQueryData from "hooks/useQueryData";
import i18next from "../translations";

import { STATUSES_DROPDOWN } from "components/DropdownFilter/DropdownOption";
import ShipmentAtPort from "components/svgs/ShipmentAtPort";
import ShipmentBookedNoSquare from "components/svgs/ShipmentBookedNoSquare";
import ShipmentReceivedNoSquare from "components/svgs/ShipmentReceivedNoSquare";
import { EventDateTimeProps, LocationProps, ModeProps, QueryKeysProps } from "interface/general";
import { GraphViewDataProps } from "interface/graphView";
import { ShipmentDetailsProps, ShipmentEventProps, ShipmentSummaryProps } from "interface/shipment";
import {
  cloneDeep,
  compact,
  filter,
  get,
  identity,
  isEmpty,
  isEqual,
  isNaN,
  isNull,
  isUndefined,
  keyBy,
  last,
  mapValues,
  maxBy,
  orderBy,
  pickBy,
  random,
  range,
  round,
  sortBy,
  sumBy,
  upperFirst,
} from "lodash";
import moment from "moment-with-locales-es6";
import queryString from "query-string";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  colors,
  DATE_FORMAT,
  DATE_FORMAT_FNS,
  DATE_FORMAT_TO_DISPLAY,
  DATE_FORMAT_WITHOUT_YEAR,
  DATE_TIME_FORMAT_TO_DISPLAY,
  DEFAULT_VALUES,
  GRAPH_NODE_ICON_SIZES,
  GRAPH_NODE_SIZES,
  icons,
  MATERIAL_TYPE,
  NUMBER_DECIMAL_DIGITS,
  PICKER_TYPES,
  PORT_STATUS,
  SEPARATE_ARR_CHARACTER,
  SHIPMENT_STATUS,
  SHIPMENT_STATUSES,
  SHIPMENT_STATUS_EVENTS,
  SHIPMENT_TYPE_LIST,
  SORT_TYPES,
  TOTAL_INVENTORY_STATUSES,
  TRACKING_TYPE,
  TRANSPORT_TYPE,
  UNKNOWN_SECTION,
  YEAR_FORMAT,
} from "./constants";
import variables from "./variables";

export const isJson = (data: any) => {
  try {
    JSON.parse(data);
  } catch (e) {
    return false;
  }
  return true;
};

export const encodeAes = (data: string, secretKey: string) => {
  return cryptoJs.AES.encrypt(data, secretKey).toString();
};

export const decodeAes = (data: any, secretKey: string) => {
  try {
    const bytes = cryptoJs.AES.decrypt(data, secretKey);
    return bytes.toString(cryptoJs.enc.Utf8);
  } catch (error) {
    return {};
  }
};

export const passedValidation = (errors: any) => {
  const errorFields = filter(errors, (e: any) => e === true).length;
  if (+errorFields === 0) {
    return true;
  } else {
    return false;
  }
};

export const getRedux = (path: string, defaultValue: any) => {
  const getValueRedux = (path: string) => (object: any) => get(object, path);
  const selectValueRedux = (path: string) => createSelector(getValueRedux(path), (data) => data);
  const getSelector = (path: string) => useSelector(selectValueRedux(path));
  return getSelector(path) || defaultValue;
};

// Use to wrap source img with hostname for micro frontend loading
export const sourceWrapper = (src: string) => {
  return variables?.skyviewsUrl + src;
};

export const getConfigColor = (variable: string) => {
  const documentStyle = getComputedStyle(document.body);
  return documentStyle?.getPropertyValue(variable);
};

export const getShipmentIconColor = (eventStatus: any) => {
  //@ts-ignore

  switch (eventStatus) {
    case null:
      return {
        iconColor: colors.gray[500],
        borderColor: getConfigColor("--primary-200") || colors.gray[200],
        backgroundColor: getConfigColor("--primary-500") || colors.gray[800],
      };
    case "success":
      return {
        iconColor: "#fff",
        borderColor: getConfigColor("--primary-200") || colors.blue[200],
        backgroundColor: getConfigColor("--primary-500") || colors.blue[500],
      };
    case "failure":
      return {
        iconColor: "#fff",
        borderColor: getConfigColor("--primary-200") || colors.red[200],
        backgroundColor: getConfigColor("--primary-500") || colors.red[500],
      };
    default:
      return {
        iconColor: colors.gray[500],
        borderColor: colors.gray[200],
        backgroundColor: colors.gray[200],
      };
  }
};

export const getShipmentStatusUI = (status: any = {}) => {
  let rs: { label: string | ReactNode; color: TagThemeProps; btnColor?: string } = {
    label: "",
    color: "grey",
    btnColor: "var(--gray-400)",
  };
  switch (status) {
    case "CREATED":
      rs = {
        color: "grey",
        btnColor: "var(--gray-400)",
        label: i18next.t("Created"),
      };
      break;
    case "PICK_UP_UNSCHEDULED":
      rs = {
        color: "red",
        btnColor: "var(--red-600)",
        label: i18next.t("Pick-up unscheduled"),
      };
      break;
    case "PICKED_UP_LATE":
      rs = {
        color: "red",
        btnColor: "var(--red-600)",
        label: i18next.t("Picked-up late"),
      };
      break;
    case "LATE_SHIPMENT":
      rs = {
        color: "red",
        btnColor: "var(--red-600)",
        label: i18next.t("Late shipment"),
      };
      break;
    case "BOOKED":
      rs = {
        color: "yellow",
        btnColor: "var(--red-600)",
        label: i18next.t("Booked"),
      };
      break;
    case "INTRANSIT":
      rs = {
        color: "blue",
        btnColor: "var(--blue-600)",
        label: i18next.t("In-transit"),
      };
      break;
    case "POTENTIALLY_LATE":
      rs = {
        color: "red",
        btnColor: "var(--red-600)",
        label: i18next.t("Potentially late"),
      };
      break;
    case "DELIVERED":
      rs = {
        color: "grey",
        label: i18next.t("Delivered"),
      };
      break;
    case "DELIVERED_ON_TIME":
      rs = {
        color: "green",
        btnColor: "var(--green-600)",
        label: i18next.t("Delivered"),
      };
      break;
    case "DELIVERED_LATE":
      rs = {
        color: "red",
        btnColor: "var(--red-600)",
        label: i18next.t("Delivered Late"),
      };
      break;
    case "ONTIME":
      rs = {
        color: "green",
        btnColor: "var(--green-600)",
        label: i18next.t("On-time"),
      };
      break;
    case "RECEIVED":
      rs = {
        color: "grey",
        label: i18next.t("Received"),
      };
      break;
    case "RECEIVED_ON_TIME":
      rs = {
        color: "green",
        label: i18next.t("Received"),
      };
      break;
    case "RECEIVED_LATE":
      rs = {
        color: "red",
        label: i18next.t("Received Late"),
      };
      break;
    case "PARTIALLY RECEIVED":
      rs = {
        color: "green",
        label: i18next.t("Partially Received"),
      };
      break;
    default:
      break;
  }
  return rs;
};

export const formatMoney = (number: any) => {
  let value;
  if (number !== undefined && +number > 0) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
    value = formatter.format(+number);
  } else {
    value = `$0.00`;
  }

  return value;
};

export const formatNumberWithCommas = (number: any) => {
  return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const numFormatter = (num: any) => {
  if (num >= 1000000) {
    return num / 1000000 + "M";
  }
  if (num >= 1000) {
    return num / 1000 + "K";
  }
  return num ?? 0;
};

export const emptyFunction = (f?: any) => f;

export const getShipmentEventIcon = (event?: string, status?: string) => {
  switch (event) {
    case SHIPMENT_STATUS_EVENTS?.BOOKED:
      return <ShipmentBooked status={status} />;
    case SHIPMENT_STATUS_EVENTS?.INTRANSIT:
      return <ShipmentInTransit status={status} />;
    case SHIPMENT_STATUS_EVENTS?.DELIVERED:
      return <ShipmentDelivered status={status} />;
    case SHIPMENT_STATUS_EVENTS?.ESTDELIVERED:
    case SHIPMENT_STATUS_EVENTS?.EST_SHIPPED:
    case SHIPMENT_STATUS_EVENTS?.PASCAL_ESTDELIVERED:
      return <ShipmentEstDelivered status={""} />;
    case SHIPMENT_STATUS_EVENTS?.COMMITTED:
      return <ShipmentCommitted status={status} />;
    case SHIPMENT_STATUS_EVENTS?.RECEIVED:
      return <ShipmentReceived status={status} />;
    case SHIPMENT_STATUS_EVENTS?.AT_PORT:
      return <ShipmentAtPort status={status} />;
    // case "newestdelivered":
    //   return <ShipmentNewEstDelivered status={`failure`} />;
    default:
      return <ShipmentCreated status={status} />;
  }
};

export const renderActivitiesEventIcon = (event?: string, status?: string | null) => {
  switch (event) {
    case SHIPMENT_STATUS.CREATED:
      return <ShipmentCreatedNoSquare status={status} />;
    case SHIPMENT_STATUS.BOOKED:
      return <ShipmentBookedNoSquare status={status} />;
    case SHIPMENT_STATUS.INTRANSIT:
      return <ShipmentIntransitNoSquare status={status} />;
    case SHIPMENT_STATUS.DELIVERED:
    case SHIPMENT_STATUS.DELIVERED_LATE:
      return <ShipmentDeliveredNoSquare status={status} />;
    case SHIPMENT_STATUS.RECEIVED:
    case SHIPMENT_STATUS.RECEIVED_LATE:
      return <ShipmentReceivedNoSquare status={status} />;
    default:
      return <ShipmentCreated status={status} />;
  }
};

export const renderActivitiesStepIcon = (eventCodeType: string, code?: string) => {
  if (code) {
    switch (eventCodeType) {
      case "main":
        return (
          <div className="w-fit bg-gray500 rounded-[4px] text-white px-1 text-center">
            <span className="sm_body_b2_semi">{code}</span>
          </div>
        );
      case "normal":
        return (
          <div className="w-fit bg-[#F2F3F4] rounded-[4px] text-gray800 px-1 text-center">
            <span className="sm_body_b2_semi">{code}</span>
          </div>
        );
      default:
        <div className="w-fit bg-[#F2F3F4] rounded-[4px] text-white px-1">
          <span className="sm_body_b2_semi">{code}</span>
        </div>;
        break;
    }
  } else {
    return (
      <div className="step-dot">
        <span className="dot" />
      </div>
    );
  }
};

interface configOptions extends Partial<GraphOptions> {
  setIsChartReady: any;
  elementId: string;
  customTooltip?: any;
  customNodeTooltip?: any;
  nodes: NodeConfig[];
  edges: EdgeConfig[];
  tooltipType?: "edge-tooltip" | "tooltip";
}

export const createGraph = ({
  nodes,
  edges,
  elementId,
  setIsChartReady,
  customTooltip,
  customNodeTooltip,
}: configOptions) => {
  const container: any = document.getElementById(elementId);
  const width = container.scrollWidth;
  const height = container.scrollHeight || 492;
  const center = { x: width / 2, y: height / 2 };

  const toolbar = new G6.ToolBar({
    className: "shipment-graph-view-toolbar g6-component-toolbar",
    handleClick: (code, graph) => {
      switch (code) {
        case "zoomIn": {
          const zoomIndex = graph.getZoom();
          graph.zoomTo(zoomIndex - 0.2, center);
          break;
        }

        case "zoomOut": {
          const zoomIndex = graph.getZoom();
          graph.zoomTo(zoomIndex + 0.2, center);
          break;
        }

        case "autoZoom": {
          graph.zoomTo(1, center);
          break;
        }
        default:
          break;
      }
    },
  });

  const defaultMode: any = [
    // "zoom-canvas",
    "drag-canvas",
    "click-edge",
    "drag-node",
  ];

  if (customNodeTooltip) {
    defaultMode.push({
      type: "tooltip",
      formatText(edge: any) {
        if (customNodeTooltip) return customNodeTooltip(edge);
      },
    });
  }
  if (customTooltip) {
    defaultMode.push({
      type: "edge-tooltip",
      formatText(edge: any) {
        if (customTooltip) return customTooltip(edge);
      },
    });
  }

  const configs: GraphOptions = {
    container,
    width,
    height,
    fitView: true,
    fitViewPadding: 8,
    fitCenter: true,
    renderer: "svg",
    // minZoom: 0.5,
    layout: {
      type: "forceAtlas2",
      preventOverlap: true,
      kr: 80,
      center: [width || 300 / 2, height || 300 / 2],
      onLayoutEnd: () => setIsChartReady(true),
    },
    modes: {
      default: defaultMode,
    },
    defaultNode: {
      type: "circle",
      size: GRAPH_NODE_SIZES.base,
      style: {
        fill: colors.white,
        stroke: colors.gray[300],
        lineWidth: 1,
      },
      icon: {
        show: true,
        width: GRAPH_NODE_ICON_SIZES.base,
        height: GRAPH_NODE_ICON_SIZES.base,
      },
      labelCfg: {
        position: "bottom",
        style: {
          fill: colors.gray[800],
          fontSize: 10,
          fontWeight: 600,
          lineHeight: 12,
          fontFamily: "Nunito Sans",
          background: {
            fill: "#FAFAFD",
            stroke: "#FAFAFD",
            padding: 0,
            radius: 2,
          },
        },
      },
    },
    defaultEdge: {
      style: {
        endArrow: {
          path: "M 0,0 L -5,2 L -5,-2 Z",
        },
        stroke: colors.gray[300],
      },
      labelCfg: {
        autoRotate: true,
        style: {
          fill: colors.gray[800],
          fontSize: 10,
          fontWeight: 400,
          lineHeight: 12,
          fontFamily: "Nunito Sans",
          background: {
            fill: "#FAFAFD",
            stroke: "#FAFAFD",
            padding: [2, 4, 2, 4],
            radius: 2,
          },
        },
      },
    },
    plugins: [toolbar],
    edgeStateStyles: {
      "edge-selected": {
        lineWidth: 2,
      },
    },
  };

  const graph = new G6.Graph(configs);

  graph.on("afterlayout", () => {
    graph.fitView();
  });
  graph.data({ nodes, edges });

  graph.render();

  if (typeof window !== "undefined") {
    window.onresize = () => {
      if (!graph || graph.get("destroyed")) return;

      if (!container || !container.scrollWidth || !container.scrollHeight) return;

      graph.changeSize(container.scrollWidth, container.scrollHeight);
    };
  }
  return graph;
};

export const prepareDataForMaterialChart = (
  data: GraphViewDataProps = { nodes: [], edges: [] },
) => {
  const { nodes: oriNodes, edges: oriEdges } = data;
  const nodes: NodeConfig[] = [];
  const edges: EdgeConfig[] = [];
  oriNodes?.map((item: any) => {
    const isProduct = item.type?.code === MATERIAL_TYPE.PRODUCT;
    const issue = item.issue;
    nodes.push({
      id: item.id,
      label: item.alias,
      safetyStock: item.safetyStock,
      onHands: item.onHands,
      inTransit: item.inTransit,
      committed: item.committed,
      type: item.type,
      size: isProduct ? GRAPH_NODE_SIZES.md : GRAPH_NODE_SIZES.xs,
      style: {
        stroke: isEmpty(item.issue) ? colors.blue[100] : colors.red[100],
      },
      icon: {
        img: isProduct
          ? icons.billMaterial[issue ? "productException" : "product"]
          : icons.billMaterial[issue ? "materialException" : "material"],
        width: isProduct ? GRAPH_NODE_ICON_SIZES.md : GRAPH_NODE_ICON_SIZES.xs,
        height: isProduct ? GRAPH_NODE_ICON_SIZES.md : GRAPH_NODE_ICON_SIZES.xs,
      },
    });
  });

  oriEdges?.map((item: any) => {
    edges.push({
      source: item.nodeFrom,
      target: item.nodeTo,
      style: {
        stroke: isEmpty(item.issue) ? colors.blue[400] : colors.red[500],
      },
    });
  });

  return {
    nodes,
    edges,
  };
};

const getLineColorPortGraph = (status: string) => {
  switch (status) {
    case PORT_STATUS.BOOKED:
      return colors.yellow[500];
    case PORT_STATUS.CREATED:
      return colors.blue[500];
    case PORT_STATUS.INTRANSIT:
      return colors.gray[300];
    default:
      return colors.blue[500];
  }
};

const calcNodeSize = (maxValue: number, value: number) => {
  const minStep = maxValue / 5;
  switch (true) {
    case value <= minStep:
      return "xs";
    case minStep < value && value <= minStep * 2:
      return "sm";
    case minStep * 2 < value && value <= minStep * 3:
      return "base";
    case minStep * 3 <= value && value <= minStep * 4:
      return "md";
    case minStep * 4 < value && value <= maxValue:
      return "lg";
    default:
      return "base";
  }
};

export const prepareDataForPortChart = (data: any = { nodes: [], routes: [] }) => {
  const { ports: oriPorts, routes: oriRoutes } = data;

  const sumList: any = [];

  oriPorts?.map((item: any) => {
    const routesSelected = oriRoutes?.filter((it: any) => it.portTo === item.id);
    const total = sumBy(routesSelected, (i: any) => i?.volume?.value);
    sumList.push({
      id: item.id,
      value: total,
    });
  });
  const maxValue = maxBy(sumList, (i: any) => i.value);

  const nodes: NodeConfig[] = [];
  const edges: EdgeConfig[] = [];

  oriPorts?.map((item: any) => {
    const issue = !isEmpty(item.issue);

    const nodeSelected = sumList.find((node: any) => node.id === item.id);
    nodes.push({
      id: item.id,
      label: `${item.name} \n ${nodeSelected.value ? `$${numFormatter(nodeSelected.value)}` : ""}`,
      size: GRAPH_NODE_SIZES[calcNodeSize(maxValue.value, nodeSelected.value)],
      style: {
        stroke: issue ? colors.red[200] : colors.blue[100],
      },
      icon: {
        img: icons.seaport[issue ? "hasIssue" : "normal"],
        width: GRAPH_NODE_ICON_SIZES[calcNodeSize(maxValue.value, nodeSelected.value)],
        height: GRAPH_NODE_ICON_SIZES[calcNodeSize(maxValue.value, nodeSelected.value)],
      },

      type: issue ? "has-issue" : "normal",
    });
  });

  const sortRoutes = sortBy(oriRoutes, (it: any) => it.portFrom);

  sortRoutes?.map((item: any) => {
    const sameEdges = oriRoutes.filter(
      (it: any) => it.portFrom === item.portFrom && it.portTo === item.portTo,
    );
    const indexLine = sameEdges.findIndex((it: any) => it === item);

    const oppositeEdge = oriRoutes?.find(
      (it: any) => it.portFrom === item.portTo && it.portTo === item.portFrom,
    );
    let isCurveBelow = false;

    if (
      edges?.find(
        (it: EdgeConfig) =>
          it.source === oppositeEdge?.portFrom && it.target === oppositeEdge?.portTo,
      )
    ) {
      isCurveBelow = true;
    }
    edges.push({
      source: item.portFrom,
      target: item.portTo,
      id: `${item.id}`,
      style: {
        stroke: getLineColorPortGraph(item?.status?.code),
      },
      labelCfg: {
        style: {
          fill: item.issue ? colors.red[500] : "#212529",
        },
      },
      type: indexLine > 0 || isCurveBelow ? "quadratic" : "",
      label: item?.mode[0].toUpperCase() + item?.mode.substring(1).toLowerCase(),
      averageTime: item.averageTime,
      ...(indexLine > 0 && { curveOffset: -50 * indexLine * (isCurveBelow ? -1 : 1) }),
    });
  });
  const originPorts: string[] = [];
  const destinationPorts: string[] = [];
  oriRoutes?.forEach((route: any) => {
    const portFrom = oriPorts.find((it: any) => it.id === route.portFrom);
    const portTo = oriPorts.find((it: any) => it.id === route.portTo);
    if (!originPorts.includes(portFrom)) {
      originPorts.push(portFrom);
    }
    if (!destinationPorts.includes(portTo)) {
      destinationPorts.push(portTo);
    }
  });

  return {
    nodes,
    edges,
    totalPorts: oriPorts,
    origins: originPorts,
    destinations: destinationPorts,
  };
};

export interface RegionProps {
  region: string;
  countries: CountryProps[];
}

export interface CountryProps {
  id: string;
  name: string;
  iso2?: string;
  iso3: string;
}

export const addKeyToArray = (data: any[]) =>
  data?.map((it: any, index: number) => ({ ...it, id: index, key: index }));

export const getProductType = (type: string) => {
  switch (type) {
    case MATERIAL_TYPE.PRODUCT:
      return "Product";
    case MATERIAL_TYPE.MATERIAL:
      return "Material";
    default:
      return "Product";
  }
};

export const separateTotalOrderByStatus = (shipmentSummary: ShipmentSummaryProps[]) =>
  shipmentSummary?.map((el: ShipmentSummaryProps) => ({
    className: el?.status?.code?.toLowerCase(),
    label: SHIPMENT_STATUSES[`${el?.status?.code}`]?.labelForMinimize,
    backgroundColor: SHIPMENT_STATUSES[`${el?.status?.code}`]?.color,
    value: el?.total?.value ?? 0,
  }));

export const prepareDate = (type: "from" | "to", time: any) => {
  let utcTime = moment.utc(time, DATE_FORMAT);
  if (type === "from") {
    utcTime = utcTime.startOf("day");
  } else if (type === "to") {
    utcTime = utcTime.endOf("day");
  }
  return utcTime.unix();
};

export const getOnTimePercent = (shipmentSummary: ShipmentSummaryProps[] | undefined) => {
  const shipmentObj = shipmentSummary?.reduce(
    (acc: any, item: ShipmentSummaryProps) => ({
      ...acc,
      [item?.status?.code]: item?.total?.value,
    }),
    {},
  );
  let percent = NaN;
  if (+shipmentObj?.["DELIVERED_ON_TIME"] + +shipmentObj?.["DELIVERED_LATE"] > 0) {
    percent = +(
      (+shipmentObj?.["DELIVERED_ON_TIME"] /
        (+shipmentObj["DELIVERED_ON_TIME"] + +shipmentObj?.["DELIVERED_LATE"])) *
      100
    )?.toFixed(NUMBER_DECIMAL_DIGITS);
  }
  return percent || 0;
};

export const sortTransport = (transport: ModeProps[] | undefined) => {
  const objTransport = transport?.reduce(
    (acc: any, item: ModeProps) => ({ ...acc, [item.type]: item }),
    {},
  );
  const sortTransport: ModeProps[] = [];
  TRANSPORT_TYPE.forEach((it: string) => {
    if (!isEmpty(objTransport?.[it])) sortTransport.push(objTransport?.[it]);
  });
  return sortTransport;
};

export const getEventDateTime = (datetime: EventDateTimeProps, timezone = "") => {
  const { date, time } = datetime ?? {};
  const isHasTime = time !== null;
  const convertDateTime = (date + time) * 1000;
  let rs: any = {};
  if (timezone && convertDateTime) {
    const tzNumber = getTimezoneOffset(timezone, convertDateTime) / (60 * 60 * 1000);
    rs = {
      date: format(utcToZonedTime(convertDateTime, timezone), DATE_FORMAT_FNS),
      datetime: format(
        utcToZonedTime(convertDateTime, timezone),
        `${DATE_FORMAT_FNS}${isHasTime ? " - HH:mm" : ""}`,
      ),
      offsetStr: `GMT${tzNumber > 0 ? "+" : ""}${tzNumber}`,
      dateWithoutYear: format(utcToZonedTime(convertDateTime, timezone), DATE_FORMAT_WITHOUT_YEAR),
    };
  } else if (convertDateTime) {
    rs = {
      date: moment.utc(convertDateTime).format(DATE_FORMAT_TO_DISPLAY),
      datetime: moment
        .utc(convertDateTime)
        .format(isHasTime ? DATE_TIME_FORMAT_TO_DISPLAY : DATE_FORMAT_TO_DISPLAY),
      dateWithoutYear: moment.utc(convertDateTime).format(DATE_FORMAT_WITHOUT_YEAR),
    };
  }
  return rs;
};

export const isValidPercent = (percent: number) => percent >= 0;

export const ordinalSuffixOf = (i: number, t: any) => {
  switch (i) {
    case 1:
      return t("1st");
    case 2:
      return t("2nd");
    case 3:
      return t("3rd");
    case 4:
      return t("4th");
    case 5:
      return t("5th");
    case 6:
      return t("6th");
    case 7:
      return t("7th");
    case 8:
      return t("8th");
    case 9:
      return t("9th");
    case 10:
      return t("10th");
    case 11:
      return t("11th");
    case 12:
      return t("12th");
    case 13:
      return t("13th");
    case 14:
      return t("14th");
    case 15:
      return t("15th");
    case 16:
      return t("16th");
    case 17:
      return t("17th");
    case 18:
      return t("18th");
    case 19:
      return t("19th");
    case 20:
      return t("20th");
    default:
      break;
  }
};

export const trimSpaces = (str: string) => str.replace(/\s+/g, " ").replace(/^\s+|\s+$/, "");

export const trimSpacesAll = (str: string) => str.replaceAll(" ", "");

export const scrollToElement = (elementId: string) => {
  const shipmentListEle = document?.getElementById(elementId);
  shipmentListEle && shipmentListEle.scrollIntoView();
};

export const kFormatter = (num: number) => {
  return Math.abs(num) > 999
    ? formatNumberWithCommas(Math.round(Math.sign(num) * (Math.abs(num) / 1000))) + "k"
    : Math.sign(num) * Math.abs(num);
};

export const formatPercent = (percentValue: number) => {
  percentValue = Math.abs(percentValue);
  percentValue = Number.isInteger(percentValue)
    ? percentValue
    : parseFloat(percentValue.toFixed(+percentValue > 100 ? 0 : NUMBER_DECIMAL_DIGITS));

  return `${formatNumberWithCommas(percentValue)}%`;
};

// https://pascalstudio.atlassian.net/wiki/spaces/CON/pages/4557013064/Products+Page#Trending-Color
export const getTrendingAssets = (status: string, percent: number) => {
  if (
    (status === TOTAL_INVENTORY_STATUSES.lostUnits.label && percent > 0) ||
    (status !== TOTAL_INVENTORY_STATUSES.lostUnits.label && percent < 0)
  ) {
    return { theme: "red", color: colors.red[500] };
  }

  if (
    (status === TOTAL_INVENTORY_STATUSES.lostUnits.label && percent < 0) ||
    (status !== TOTAL_INVENTORY_STATUSES.lostUnits.label && percent > 0)
  ) {
    return { theme: "green", color: colors.green[500] };
  }

  return { theme: "grey", color: colors.gray[800] };
};

export const showInDev = () =>
  ["development", "local"]?.includes(process?.env?.REACT_APP_ENVIRONMENT as any);

export const arrFromRange = (from: number, to: number) => {
  return range(+from + 1, +to + 1);
};

export const getGeneralShipmentStatus = (status: any, deliveryType: string) => {
  let shipmentStatus = { ...status };
  if (status?.code === SHIPMENT_STATUS.DELIVERED) {
    switch (deliveryType) {
      case "LATE":
        shipmentStatus = { ...status, code: SHIPMENT_STATUS.DELIVERED_LATE };
        break;
      case "ON-TIME":
        shipmentStatus = { ...status, code: SHIPMENT_STATUS.DELIVERED_ON_TIME };
        break;
      default:
        break;
    }
  }
  if (status?.code === SHIPMENT_STATUS.RECEIVED) {
    switch (deliveryType) {
      case "LATE":
        shipmentStatus = { ...status, code: SHIPMENT_STATUS.RECEIVED_LATE };
        break;
      case "ON-TIME":
        shipmentStatus = { ...status, code: SHIPMENT_STATUS.RECEIVED_ON_TIME };
        break;
      default:
        break;
    }
  }
  return shipmentStatus;
};

export const getDefaultPickerTime = () => {
  const defaultFrom = moment().startOf("month").format(DATE_FORMAT);
  const defaultTo = moment().endOf("month").format(DATE_FORMAT);
  // if (moment(defaultTo) > moment()) {
  //   defaultTo = moment().format(DATE_FORMAT);
  // }
  return {
    from: defaultFrom,
    to: defaultTo,
    pickerType: PICKER_TYPES?.monthly,
  };
};

export const formatLastUpdatedTime = (time?: any) =>
  time ? moment.unix(time).fromNow() : DEFAULT_VALUES?.noData;

export const randomNumber = ({ min, max }: { min: number; max: number }) => {
  return Math.ceil(Math.random() * (max - min) + min);
};

export const getShipmentLocation = (
  generalStatus: string,
  events: ShipmentEventProps[],
  origin: LocationProps,
  destination: LocationProps,
) => {
  let data: any = {};
  const filterEvents = events?.filter(
    (event: any) => event?.status?.code === generalStatus && event?.location?.location,
  );
  const event = last(filterEvents);
  if (!event?.location?.location) {
    switch (generalStatus) {
      case SHIPMENT_STATUS?.BOOKED:
      case SHIPMENT_STATUS?.INTRANSIT:
        data = {
          location: origin?.location,
          timezone: origin?.timezone,
        };
        break;
      case SHIPMENT_STATUS?.DELIVERED:
        data = {
          location: destination?.location,
          timezone: destination?.timezone,
        };
        break;
      default:
        break;
    }
  } else
    data = {
      ...event?.location,
      datetime: event?.eventDateTime?.date ? `${event?.eventDateTime?.date}` : "",
    };

  const tzStr = data?.timezone
    ? getTimezoneOffset(data?.timezone, data?.datetime ? new Date(data?.datetime) : new Date()) /
      (60 * 60 * 1000)
    : "";

  return {
    ...data,
    dstOffsetStr: tzStr ? `GMT${tzStr > 0 ? "+" : ""}${tzStr}` : "",
  };
};

type ConvertToDisplayType = "number" | "percent" | "lastUpdatedTime" | "decimalNumber" | "money";
export const convertToDisplay = (value: any, type = "number" as ConvertToDisplayType) => {
  switch (type) {
    case "lastUpdatedTime": {
      return !isEmpty(value) && !isNaN(value)
        ? moment.unix(value).fromNow()
        : DEFAULT_VALUES.noData;
    }
    case "number":
    case "decimalNumber":
    case "percent": {
      if (isValueInValid(value)) return DEFAULT_VALUES.noData;
      if (type === "number") return formatNumberWithCommas(+value);
      if (type === "decimalNumber")
        return formatNumberWithCommas(+value.toFixed(NUMBER_DECIMAL_DIGITS));
      if (type === "percent") return formatPercent(+value);
      break;
    }
    case "money": {
      return value ? formatMoney(value) : DEFAULT_VALUES.noData;
    }
    default:
      return value;
  }
};

export const isValueInValid = (value: any) =>
  value === Infinity || isNull(value) || isUndefined(value) || isNaN(+value);

export const calcPercent = (a: any, b: any) => (a / b) * 100;

export const getCarrierOverviewTrendingAssets = ({ currentValue, previousValue }: any) => {
  if (+currentValue > +previousValue) {
    return { theme: "red", color: colors.red[500] };
  }

  if (+currentValue < +previousValue) {
    return { theme: "green", color: colors.green[500] };
  }

  return { theme: "grey", color: colors.gray[800] };
};

export const containsAll = (needles: any, haystack: any) =>
  needles.every(Set.prototype.has, new Set(haystack));

export const transformInputItemsForStackProgress = (value: any, total: number) => {
  const sumValues = (value?.onTime ?? 0) + (value?.late ?? 0) + (value?.noData ?? 0);

  const items = [
    {
      label: i18next.t("On-time"),
      color: colors.green[300],
      percent: calcPercent(value?.onTime, total),
      value: value?.onTime ?? 0,
    },
    {
      label: i18next.t("Late"),
      color: colors.red[400],
      percent: calcPercent(value?.late, total),
      value: value?.late ?? 0,
    },
    {
      label: i18next.t("No Data"),
      color: colors.gray[300],
      percent:
        sumValues > 0
          ? 100 - (calcPercent(value?.onTime, total) || 0) - (calcPercent(value?.late, total) || 0)
          : null,
      value: value?.noData ?? 0,
    },
  ];

  return processPercents(items);
};

export const formatPlanValue = (value: any) => (value === 0 ? null : value);

export const withoutFields = (data: any, keys: any[]) => {
  const newData = { ...data };
  keys?.map((key: any) => {
    delete newData[key];
  });
  return newData;
};

export const getRegionSortField = (sortBy: string) => {
  switch (sortBy) {
    case "name":
      return "region.name";
    default:
      return "region.name";
  }
};

export const getCountrySortField = (sortBy: string) => {
  switch (sortBy) {
    case "name":
      return "country.countryName";
    default:
      return "country.countryName";
  }
};

export const getProductSortField = (sortBy: string) => {
  switch (sortBy) {
    case "name":
      return "info.name";
    default:
      return "info.name";
  }
};

export const getSitesSortField = (sortBy: string) => {
  switch (sortBy) {
    case "name":
      return "site.siteName";
    default:
      return "site.siteCode";
  }
};

export const processPercents = (arr?: any) => {
  const sumValues =
    cloneDeep(arr ?? [])?.reduce((sum: number, item: any) => (sum += item?.value), 0) ?? 0;
  if (arr?.length === 0 || sumValues === 0) return arr;

  const initialArrayLabelsOrder = [...arr]?.map((item: any) => item?.label);
  let sumPercent = 0;

  arr = orderBy(arr, ["percent"], ["asc"])?.map((item: any, index: number) => {
    if (isValueInValid(item?.percent)) item.percent = 0;

    if (index < arr?.length - 1) {
      item.percent = round(item?.percent, NUMBER_DECIMAL_DIGITS);
    } else {
      item.percent = 100 - sumPercent;
    }

    sumPercent += item.percent;

    return item;
  });

  const result: any = [];
  initialArrayLabelsOrder?.forEach((label: string) => {
    const mapping = arr?.find((item: any) => item?.label === label);

    if (!isEmpty(mapping)) {
      result.push(mapping);
    }
  });

  return result;
};

export const sortRegions = (regions: any = [], sortBy: string, sortValue: any) => {
  const regionItems = cloneDeep(regions)?.map((regionItem: any) => {
    return {
      ...regionItem,
      region: {
        ...regionItem?.region,
        name: capitalizeFirstLetter(regionItem?.region?.regionName),
      },
    };
  });

  const unknownRegion =
    regionItems?.find((region: any) => region?.region?.regionCode === UNKNOWN_SECTION.code) ?? {};
  const validRegions =
    regionItems?.filter((region: any) => region?.region?.regionCode !== UNKNOWN_SECTION.code) ?? [];

  const validRegionsSorted = orderBy(validRegions, [getRegionSortField(sortBy)], [sortValue]);

  if (isEmpty(unknownRegion)) return validRegionsSorted;

  let sumUnknownValue = 0;

  for (const objectKey in unknownRegion) {
    if (objectKey !== "region") {
      for (const childObjectKey in unknownRegion[objectKey]) {
        if (
          unknownRegion?.[objectKey]?.[childObjectKey] &&
          Object.keys(unknownRegion?.[objectKey]?.[childObjectKey])?.length > 0
        ) {
          for (const childChildObjectKey in unknownRegion?.[objectKey]?.[childObjectKey]) {
            sumUnknownValue +=
              unknownRegion?.[objectKey]?.[childObjectKey]?.[childChildObjectKey] ?? 0;
          }
        } else {
          sumUnknownValue += unknownRegion?.[objectKey]?.[childObjectKey] ?? 0;
        }
      }
    }
  }

  sumUnknownValue = sumUnknownValue + unknownRegion?.containers + unknownRegion?.totalShipments;

  if (sumUnknownValue === 0) return validRegionsSorted;

  return [...validRegionsSorted, unknownRegion];
};

export const sortCountries = (countries: any = [], sortBy: string, sortValue: any) => {
  const countryItems = cloneDeep(countries)?.map((countryItem: any) => {
    countryItem.country.name = capitalizeFirstLetter(countryItem?.country?.countryName) ?? "";

    return countryItem;
  });

  const unknownCountry =
    countryItems?.find((country: any) => country?.country?.countryCode === UNKNOWN_SECTION.code) ??
    {};
  const validCountries =
    countryItems?.filter(
      (country: any) => country?.country?.countryCode !== UNKNOWN_SECTION.code,
    ) ?? [];

  const validCountriesSorted = orderBy(validCountries, [getCountrySortField(sortBy)], [sortValue]);

  if (isEmpty(unknownCountry)) return validCountriesSorted;

  let sumUnknownValue = 0;

  for (const objectKey in unknownCountry) {
    if (objectKey !== "country") {
      for (const childObjectKey in unknownCountry[objectKey]) {
        if (
          unknownCountry[objectKey][childObjectKey] &&
          Object.keys(unknownCountry[objectKey][childObjectKey])?.length > 0
        ) {
          for (const childChildObjectKey in unknownCountry[objectKey][childObjectKey]) {
            sumUnknownValue += unknownCountry[objectKey][childObjectKey][childChildObjectKey] ?? 0;
          }
        } else {
          sumUnknownValue += unknownCountry[objectKey][childObjectKey] ?? 0;
        }
      }
    }
  }

  if (sumUnknownValue === 0) return validCountriesSorted;

  return [...validCountriesSorted, unknownCountry];
};

export const sortProducts = (productList: any = [], sortBy: string, sortValue: any) => {
  const productItems = cloneDeep(productList);
  // ?.map((item: any) => {
  //   item.info.name = capitalizeFirstLetter(item?.info?.name) ?? "";
  //   return item;
  // });

  const unknownProduct =
    productItems?.find((productList: any) => productList?.info?.code === UNKNOWN_SECTION.code) ??
    {};
  const validProducts =
    productItems?.filter((productList: any) => productList?.info?.code !== UNKNOWN_SECTION.code) ??
    [];

  const validProductsSorted = orderBy(validProducts, [getProductSortField(sortBy)], [sortValue]);

  if (isEmpty(unknownProduct)) return validProductsSorted;

  let sumUnknownValue = 0;

  for (const objectKey in unknownProduct) {
    if (objectKey !== "info") {
      for (const childObjectKey in unknownProduct[objectKey]) {
        sumUnknownValue += unknownProduct[objectKey][childObjectKey] ?? 0;
      }
    }
  }

  if (sumUnknownValue === 0) return validProductsSorted;

  return [...validProductsSorted, unknownProduct];
};

export const randomUniqueKey = () => random(11111, 99999);

export const capitalizeFirstLetter = (str: string) => {
  return str?.length > 1 ? str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase() : str;
};

const getShipmentTypeLabel = (shipmentObj: any, type: any) => {
  return (
    <>
      {upperFirst(type)}
      <span className="ml-[5px]">
        {shipmentObj?.isFetchingTotalCount ? (
          <SyncOutlined spin className="text-[12px]" />
        ) : (
          <>({convertToDisplay(shipmentObj?.totalCountObj?.[type] ?? 0, "number")})</>
        )}
      </span>
    </>
  );
};

export const getShipmentTypeOptions = (shipmentObj: any, QUERY_DATA: any, onChangeFilter: any) => {
  const SHIPMENT_TYPE = mapValues(keyBy(SHIPMENT_TYPE_LIST, "name"), "name");
  return [
    {
      label: getShipmentTypeLabel(shipmentObj, SHIPMENT_TYPE?.exceptions),
      value: SHIPMENT_TYPE?.exceptions,
      onClick: () => {
        onChangeFilter(
          withoutFields(
            {
              ...QUERY_DATA,
              shipmentType: SHIPMENT_TYPE?.exceptions,
            },
            ["shipmentPageIndex", "shipmentPageSize"],
          ),
        );
      },
    },
    {
      label: getShipmentTypeLabel(shipmentObj, SHIPMENT_TYPE?.followed),
      value: SHIPMENT_TYPE?.followed,
      onClick: () => {
        onChangeFilter(
          withoutFields(
            {
              ...QUERY_DATA,
              shipmentType: SHIPMENT_TYPE?.followed,
            },
            ["shipmentPageIndex", "shipmentPageSize"],
          ),
        );
      },
    },
    {
      label: getShipmentTypeLabel(shipmentObj, SHIPMENT_TYPE?.all),
      value: SHIPMENT_TYPE?.all,
      onClick: () => {
        onChangeFilter(
          withoutFields(
            {
              ...QUERY_DATA,
              shipmentType: SHIPMENT_TYPE?.all,
            },
            ["shipmentPageIndex", "shipmentPageSize"],
          ),
        );
      },
    },
  ];
};

export const getTrackingTypeOptions = (QUERY_DATA: any, onChangeFilter: any) => {
  return [
    {
      label: upperFirst(TRACKING_TYPE?.CONTAINERS_LABEL),
      value: TRACKING_TYPE?.CONTAINERS,
      icon: <ContainersInactive />,
      activeIcon: <ContainersActive />,
      onClick: () => {
        onChangeFilter(
          withoutFields(
            {
              ...QUERY_DATA,
              trackingType: TRACKING_TYPE?.CONTAINERS,
            },
            [
              "shipmentType",
              "shipmentStatuses",
              "orderShipment",
              "sortShipmentBy",
              "shipmentPageIndex",
              "shipmentPageSize",
              "shipmentExceptions",
            ],
          ),
        );
      },
    },
    {
      label: upperFirst(TRACKING_TYPE?.SHIPMENTS_LABEL),
      value: TRACKING_TYPE?.SHIPMENTS,
      icon: <ShipV2Inactive />,
      activeIcon: <ShipV2Active />,
      onClick: () => {
        onChangeFilter(
          withoutFields(
            {
              ...QUERY_DATA,
              trackingType: TRACKING_TYPE?.SHIPMENTS,
            },
            [
              "containerStatuses",
              "orderContainer",
              "sortContainerBy",
              "containerPageIndex",
              "containerPageSize",
              "containerExceptions",
            ],
          ),
        );
      },
    },
  ];
};

export const getStatusAndDeliveryType = (shipment: ShipmentDetailsProps) => {
  const { isDeliveredOnTime, isReceivedOnTime, status: shipmentStatus } = shipment ?? {};

  const flagOfStatus =
    shipmentStatus?.code === SHIPMENT_STATUS?.DELIVERED ? isDeliveredOnTime : isReceivedOnTime;
  const deliveryType = flagOfStatus ? "ON-TIME" : flagOfStatus === false ? "LATE" : "";

  return { status: shipmentStatus, deliveryType };
};

export const isShowListFilter = (containers: any = {}, shipments?: any, trackingType?: any) => {
  const QUERY_DATA = useQueryData();
  const { statuses } = QUERY_DATA;
  if (containers?.isLoading || shipments?.isLoading) return true;
  if (trackingType && trackingType === TRACKING_TYPE?.SHIPMENTS) {
    return !(isEmpty(shipments?.shipmentList) && !statuses);
  } else {
    return !(isEmpty(containers?.containerList) && !statuses);
  }
};

export const getObjectWithKeys = (object: any, keys: QueryKeysProps[]) => {
  const result: any = {};
  for (const key of keys) {
    if (object?.[key]) result[key] = object?.[key];
  }
  return result;
};

export const getStringParamsWithKeys = (keys: QueryKeysProps[]) => {
  const QUERY_DATA = useQueryData();
  const result: any = getObjectWithKeys(QUERY_DATA, keys);
  return queryString?.stringify(result);
};

export const getPositionDropdown = (containers: any = {}, shipments?: any, trackingType?: any) => {
  if (trackingType && trackingType === TRACKING_TYPE?.SHIPMENTS) {
    return shipments?.shipmentList?.length === 1 ? "top" : "bottom";
  } else {
    return containers?.containerList?.length === 1 ? "top" : "bottom";
  }
};

export const getDropdownPosition = (list: any[]) => {
  return list?.length === 1 ? "top" : "bottom";
};

export const cleanObject = (object: any) => {
  return pickBy(object, identity);
};

export const parseRequestParams = (requestData: any = {}) => {
  const {
    from,
    to,
    carriers,
    products,
    origins,
    warehouses,
    destinationRegion,
    originRegion,
    sortBy,
    order,
    page,
    pageSize,
    source,
    productFamily,
    productCategory,
    product,
    statuses,
    exceptions,
    destinationCountry,
    shipmentType,
    stid,
    shipmentTrackingId,
    isFollowed,
    searchText,
    carrierCode,
    originCountry,
    destinationSite,
    modes,
    organizationPublicKey,
    shipmentPublicKey,
  } = requestData;

  const result: any = {
    from: from && prepareDate("from", from),
    to: to && prepareDate("to", to),
    modes: modes?.split(SEPARATE_ARR_CHARACTER),
    carriers: carriers?.split(SEPARATE_ARR_CHARACTER),
    productHierarchies: parseFilterMultipleParams(products),
    originRegion,
    originHierarchies: parseFilterMultipleParams(origins),
    originCountry,
    destinationRegion,
    destinationHierarchies: parseFilterMultipleParams(warehouses),
    destinationCountry,
    page: +page,
    pageSize: +pageSize,
    source,
    productFamily,
    productCategory,
    product,
    statuses: statuses?.split(SEPARATE_ARR_CHARACTER),
    exceptions: exceptions?.split(SEPARATE_ARR_CHARACTER),
    shipmentTypes: shipmentType && [shipmentType],
    stid,
    shipmentTrackingId,
    isFollowed,
    searchText,
    carrierCode,
    destinationSite,
    organizationPublicKey,
    shipmentPublicKey,
  };
  if (!isEmpty(sortBy) && !isEmpty(order)) {
    result.sort = { [sortBy]: SORT_TYPES?.[order?.toUpperCase()] };
  }
  return cleanObject(result);
};

export const getApiKey = (prefix: string, requestData: any) => {
  const newRequestData: any = JSON?.stringify(requestData)?.replace(/\"([^(\")"]+)\":/g, "$1:");
  return `${prefix}-${queryString?.stringify(newRequestData)}`;
};

export const isEnabledCallApi = (from: string, to: string) => {
  return !isEmpty(from) && !isEmpty(to);
};

export const renderHighlight = (text: string, keyword: string) => {
  const index = text?.indexOf(keyword);
  if (index === -1) return text;
  const first = text?.substring(0, index);
  const highlight = text?.substring(index, index + keyword?.length);
  const last = text?.substring(index + keyword?.length);
  return (
    <div>
      {first}
      <span className="text-blue500">{highlight}</span>
      {last}
    </div>
  );
};

export const parseFilterMultipleParams = (data: any) => {
  const preparedDataList = !isEmpty(data)
    ? compact(
        cloneDeep(data)?.map((it: any) => {
          if (
            [STATUSES_DROPDOWN.checked, STATUSES_DROPDOWN.indeterminate]?.includes(
              it?.status as string,
            )
          ) {
            if (it?.items) {
              it.items = parseFilterMultipleParams(it?.items);
              return {
                value: it?.value?.split(SEPARATE_ARR_CHARACTER)?.[0],
                items: it?.items,
              };
            } else {
              return {
                value: it?.value?.split(SEPARATE_ARR_CHARACTER)?.[0],
              };
            }
          }
        }),
      )
    : null;

  return preparedDataList;
};

export const getBreadcrumbData = ({
  currentBreadcrumbData,
  newBreadcrumbData,
  currentDynamicId,
  newDynamicId,
}: any) => {
  if (
    !isEqual(currentDynamicId, newDynamicId) ||
    (!isEmpty(newBreadcrumbData) && !isEqual(currentBreadcrumbData, newBreadcrumbData))
  ) {
    return newBreadcrumbData;
  } else {
    return currentBreadcrumbData;
  }
};

export const getBreadcrumbObj = (currentBreadcrumbObj: any, newBreadcrumbObj: any) => {
  const breadcrumbData = getBreadcrumbData({
    currentBreadcrumbData: currentBreadcrumbObj?.breadcrumbData,
    currentDynamicId: currentBreadcrumbObj?.dynamicId,

    newBreadcrumbData: newBreadcrumbObj?.newBreadcrumbData,
    newDynamicId: newBreadcrumbObj?.newDynamicId,
  });
  const dynamicId = newBreadcrumbObj?.newDynamicId;
  return {
    breadcrumbData,
    dynamicId,
  };
};

export const getAtPortEvent = (data: ShipmentDetailsProps) => {
  const { mode, events } = data;
  let atPortEvents: any;
  if (mode === "SEA") {
    atPortEvents = events.find(
      (event: ShipmentEventProps) => event?.code === "VA" && !!event?.eventDateTime?.date,
    );
  }
  if (["AIR", "ROAD"].includes(mode))
    atPortEvents = events.find(
      (event: ShipmentEventProps) => event?.code === "X4" && !!event?.eventDateTime?.date,
    );
  return atPortEvents;
};

export const getUnixEventTime = (evenTime: string) => {
  const hour = evenTime.substring(0, 2);
  const mins = evenTime.substring(2, 4);
  return +hour * 60 * 60 + +mins * 60;
};

export const renderTimeRangeEventNode = (
  eventDateTime: any,
  timeRange: any,
  isGetFullDateTime = false,
) => {
  if (!isEmpty(timeRange)) {
    if (
      moment.utc(timeRange?.from?.date * 1000).format(YEAR_FORMAT) ===
      moment.utc(timeRange?.to?.date * 1000).format(YEAR_FORMAT)
    ) {
      const { dateWithoutYear: from, datetime: fromFull } = getEventDateTime(timeRange?.from);
      const { date: to, datetime: toFull } = getEventDateTime(timeRange?.to);
      if (isGetFullDateTime) {
        return `${fromFull} - ${toFull}`;
      }
      return `${from} - ${to}`;
    } else {
      const { date: from, datetime: fromFull } = getEventDateTime(timeRange?.from);
      const { date: to, datetime: toFull } = getEventDateTime(timeRange?.to);
      if (isGetFullDateTime) {
        return `${fromFull} - ${toFull}`;
      }
      return `${from} - ${to}`;
    }
  } else {
    const { date } = getEventDateTime(eventDateTime);
    return date;
  }
};

export const getVariance = (first: any, second: any, t: any) => {
  if (!first || !second) {
    return "-";
  }

  const firstDate = moment.utc((first.date + first.time) * 1000);
  const secondDate = moment.utc((second.date + second.time) * 1000);

  const isTime = Math.abs(first.date + first.time - (second.date + second.time)) < 86400;

  const varianceUnit = isTime ? "hour" : "day";
  const variance = secondDate.diff(firstDate, varianceUnit, true);
  const varianceToDisplay = Math.ceil(Math.abs(variance));

  const varianceString =
    varianceToDisplay === 0
      ? `0 ${t(varianceUnit)}`
      : `${varianceToDisplay} ${t(varianceUnit + (varianceToDisplay > 1 ? "s" : ""))}`;

  const varianceDirection = variance > 0 ? t("early") : variance < 0 ? t("late") : "";

  const varianceClass = variance >= 0 ? "text-green500" : "text-red500";

  return (
    <span className={varianceClass}>
      {varianceString} {varianceDirection}
    </span>
  );
};
