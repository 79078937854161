import ActivitiesListView from "./ActivitiesListView";

const ActivitiesListContainer = ({
  shipmentDetails,
  isLoading,
  isOpenActivities,
  setIsOpenActivities,
  tabActive
}: any) => {
  return (
    <ActivitiesListView
      shipmentDetails={shipmentDetails}
      {...{ isOpenActivities, setIsOpenActivities, isLoading, tabActive }}
    />
  );
};

export default ActivitiesListContainer;
