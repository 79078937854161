import { Dropdown, Menu } from "antd";
import USAFlag from "components/svgs/UsaFlag";
// import VietNamFlag from "icons/VietNamFlag";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changeLanguage } from "store/actions/app";
import { getRedux } from "store/utils";
import "./LanguageDropdown.scss";
import VietNamFlag from "components/svgs/VietNamFlag";

const LanguageDropdown = () => {
  const DropdownData = [
    {
      name: "English",
      icon: <USAFlag />,
      code: "en",
    },
    {
      name: "Vietnamese",
      icon: <VietNamFlag />,
      code: "vi",
    },
  ];

  const language: any = getRedux(`app.currentLanguage`, "en");
  const dispatch = useDispatch();
  const [languageSelected, setLanguageSelected]: any = useState(DropdownData[0]);
  useEffect(() => {
    const selected = DropdownData.find((it: any) => it.code === language);
    setLanguageSelected(selected);
  }, [language]);

  const { t } = useTranslation();

  return (
    <div className="language-dropdown-wrapper">
      <Dropdown
        overlayClassName="rounded sm-language-dropdown"
        className="hover:cursor-pointer"
        trigger={["click"]}
        overlay={
          <Menu>
            {DropdownData.map((item: any, index: number) => (
              <Menu.Item
                key={index}
                onClick={() => {
                  dispatch(changeLanguage(item?.code));
                  localStorage.setItem("currentLanguage", item?.code)
                }}
              >
                {item.name}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <div className="rounded border-gray200 hover:border-blue200 hover:text-blue500 hover:bg-blue50 border-[1px] border-solid flex flex-row h-[32px] items-center">
          <div className="py-[6px] px-[16px] sm_body_b2_reg">{languageSelected?.name}</div>
          <div className="bg-gray200 w-[1px] h-[30px]" />
          <div className=" px-[8px] flex">{languageSelected?.icon}</div>
        </div>
      </Dropdown>
    </div>
  );
};

export default LanguageDropdown;
