import CopyButton from "components/Button/CopyButton";
import DataExceptionTooltip from "components/DataExceptionTooltip";
import Image from "components/Image";
import { Tooltip } from "console-system-components";
import { ExceptionProps } from "interface/general";
import { cloneDeep } from "lodash";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import {
  colors,
  DATA_EXCEPTION_MESSAGE,
  DATA_EXCEPTION_TYPE,
  DEFAULT_VALUES,
  TRANSPORT_ICON_FILTER,
} from "scripts/constants";
import { formatMoney } from "scripts/helpers";

const ShipmentInformation = ({ shipmentItemData }: any) => {
  const { t } = useTranslation();

  const billOfLading =
    shipmentItemData?.oceanBillNo ??
    shipmentItemData?.billOfLadingNumber ??
    shipmentItemData?.houseAirWayBillNo;

  const dataException = cloneDeep(shipmentItemData?.dataExceptions)?.filter(
    (it: ExceptionProps) => it?.exception === DATA_EXCEPTION_TYPE.INCORRECT_BILL_OF_LADING_FORMAT,
  );
  const isAbleToMapExceptionCode = dataException?.some(
    (it: any) => DATA_EXCEPTION_MESSAGE[`${it?.exception}`],
  );

  return (
    <div className="sm_body_b3_reg sm-head sm-head-1 p-0 mb-6">
      <div className="flex gap-4">
        <div className="sm-head-item flex tablet:flex-1 gap-4">
          <Image
            src={TRANSPORT_ICON_FILTER[`${shipmentItemData?.mode}`]}
            className="w-[24px] h-[24px]"
            type="favicon"
          />
          <div className="flex flex-col">
            <div className="sm-head-item-label">
              <span className="mr-2">{t("Bill of Lading")}</span>
              <span>
                {isAbleToMapExceptionCode && (
                  <DataExceptionTooltip dataExceptions={dataException} />
                )}
              </span>
            </div>
            <div className="sm_body_b2_semi sm-head-item-value flex gap-2">
              {shipmentItemData?.trackingWebsiteUrl ? (
                <Tooltip
                  className="group"
                  placement="top"
                  title={
                    <span className="text-white">{t("Open carrier public tracking website")}</span>
                  }
                  color={colors?.gray[600]}
                >
                  <a
                    className="text-blue500 group-hover:underline hover:text-blue600"
                    href={shipmentItemData?.trackingWebsiteUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {billOfLading ?? DEFAULT_VALUES?.noData}
                  </a>
                </Tooltip>
              ) : (
                billOfLading ?? DEFAULT_VALUES?.noData
              )}
              {billOfLading && <CopyButton message={billOfLading ?? ""} />}
            </div>
          </div>
        </div>
        <div className="sm-head-item tablet:flex-1">
          <div className="sm-head-item-label">{t("Reference #")}</div>
          <div className="sm_body_b2_semi sm-head-item-value">
            <span className={`sm_body_b2_semi`}>
              {shipmentItemData?.customerReferenceNumber ?? DEFAULT_VALUES?.noData}
            </span>
          </div>
        </div>
        {shipmentItemData?.mode == "SEA" && (
          <div className="sm-head-item tablet:flex-1">
            <div className="sm-head-item-label">{t("Vessel Code")}</div>
            <div className="sm_body_b2_semi sm-head-item-value">
              <span className={`sm_body_b2_semi`}>
                {shipmentItemData?.vesselCode
                  ? formatMoney(shipmentItemData?.vesselCode)
                  : DEFAULT_VALUES?.noData}
              </span>
            </div>
          </div>
        )}
        {/* <Col {...{ xs: 8 }}>
          <div className="sm-head-item hidden">
          </div>
        </Col> */}
      </div>
    </div>
  );
};

export default memo(ShipmentInformation);
