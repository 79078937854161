import CheckCircle from "components/svgs/CheckCircle";
import Copy from "components/svgs/Copy";
import Download from "components/svgs/Download";
import { Button, Modal } from "console-system-components";
import useWindowDimensions from "hooks/useWindowDimensions";
import QRCode from "qrcode.react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { emptyFunction } from "scripts/helpers";

const ShareOptionModal = ({ isOpenShare, setIsOpenShare = emptyFunction, billOfLading }: any) => {
  const { t } = useTranslation();
  const [copyURL, setCopyURL] = useState(window.location.href);
  const { width } = useWindowDimensions();
  const currentURL = window.location.href.split("?")?.[0];
  const handleCopyURL = () => {
    navigator?.clipboard?.writeText(copyURL);
    setCopyURL(t("The public URL has been copied to clipboard."));
  };

  const handleDownload = () => {
    const canvas = document.getElementById(`${window.location.href}-download`) as any;

    const pngUrl = canvas?.toDataURL("image/png")?.replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");

    downloadLink.href = pngUrl;
    downloadLink.download = `shipment-${billOfLading}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Modal
      title={t("Sharing options")}
      visible={isOpenShare}
      onCancel={() => {
        setIsOpenShare(false);
        setCopyURL(currentURL);
      }}
      customFooter={null}
      centered
    >
      <div className="w-full flex gap-4">
        <div className="text-blue600 bg-blue100 w-8 min-w-8 h-8 flex justify-center items-center rounded-xl">
          1
        </div>
        <div className="h-auto flex-1">
          <p className="mb-2 sm_body_b1_reg">{t("Via public link")}</p>
          <div className="bg-gray50 border border-solid border-gray200 rounded flex h-auto xs:h-9 items-center">
            <div className="px-3 py-2 flex gap-1 flex-1 items-center max-w-[378px] border border-transparent border-solid border-r-[1px] border-r-gray200">
              {copyURL !== currentURL && <CheckCircle className="text-green500 min-w-[22px]" />}
              <span
                className={`sm_body_b2_reg ${
                  copyURL === currentURL ? `break-all` : `break-word`
                }  ${width && width >= 510 ? `show1line` : ``}`}
              >
                {copyURL}
              </span>
            </div>
            <div
              className=" w-11 h-full flex items-center justify-center hover:cursor-pointer hover:text-gray100"
              onClick={handleCopyURL}
            >
              <Copy className="w-5 h-5" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-4 mt-6">
        <div className="w-8 min-w-8 h-8 text-blue600 bg-blue100 flex justify-center items-center rounded-xl">
          2
        </div>
        <div className="flex-1">
          <p className="mb-0 sm_body_b1_reg">{t("Via QR code")}</p>
          <div className="flex flex-col items-start">
            <QRCode
              id={`${window.location.href}-download`}
              value={window.location.href}
              size={450}
              includeMargin
              className="hidden"
            />
            <QRCode
              id={window.location.href}
              value={window.location.href}
              size={160}
              includeMargin
            />
            <Button
              icon={<Download />}
              type="default"
              className="w-[160px]"
              onClick={handleDownload}
            >
              {t(`Download`)}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShareOptionModal;
