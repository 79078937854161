import { Divider, Space } from "antd";
import CopyButton from "components/Button/CopyButton";
import NoData from "components/Empty";
import SpinLoading from "components/Loading/SpinLoding";
import WrapperSection from "components/WrapperSection";
import { Modal } from "console-system-components";
import { ContainerProps } from "interface/container";
import { isEmpty } from "lodash";
import React, { ReactNode, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { DEFAULT_VALUES } from "scripts/constants";
import { convertToDisplay } from "scripts/helpers";
import "./ContainerList.scss";

interface ContainerListViewProps {
  containerList: ContainerProps[];
  loading?: boolean;
  isSearchLoading?: boolean;
  onSearchDO?: any;
  collapseActiveKeys?: any;
  setCollapseActiveKeys?: any;
  shipmentDetails?: any;
  tabActive?: string;
}

const ContainerListView: React.FC<ContainerListViewProps> = ({
  containerList = [],
  loading = false,
  tabActive,
  // collapseActiveKeys = [],
  // setCollapseActiveKeys = emptyFunction,
}) => {
  const { t } = useTranslation();
  const [visibleModalDeliveryOrder, setVisibleModalDeliveryOrder] = useState(false);
  const [containerSelected, setContainerSelected]: any = useState({});

  return (
    <WrapperSection
      type="shadow"
      className={`sm-detail-container-list h-auto ${
        tabActive === `freight` ? "flex mt-0" : "hidden"
      } tablet:flex rounded-[0px] tablet:rounded-[12px]`}
    >
      {(() => {
        return (
          <>
            <Modal
              width={480}
              visible={visibleModalDeliveryOrder}
              onCancel={() => {
                setVisibleModalDeliveryOrder(false);
                setContainerSelected({});
              }}
              cancelText={t("Cancel")}
              okText={t("Apply")}
              centered
              title={`${t("Delivery Order")} # (${
                containerSelected?.deliveryOrderNumbers?.filter((it: string) => !!it)?.length
              })`}
              className="container-ids-modal"
            >
              <div>
                {containerSelected?.deliveryOrderNumbers
                  ?.filter((it: string) => !!it)
                  .map((it: string) => (
                    <div
                      key={JSON.stringify(it)}
                      className="flex w-full gap-2 py-2 items-center border border-transparent border-solid border-b border-b-gray200"
                    >
                      <div className="sm_body_b1_reg py-1">{it}</div>
                      <CopyButton message={it} />
                    </div>
                  ))}
              </div>
            </Modal>
            <div className="sm-header">
              <div className="sm-header-search-wrapper">
                <div className="title sm_sub_title_semi">
                  <Trans
                    defaults="Freight {{totalItems}}"
                    values={{
                      totalItems: loading ? "" : `(${+containerList?.length || 0})`,
                    }}
                  />
                </div>
              </div>
              {/* <div className="sm-price-wrapper">
                <FaDollarSign size={20} />
                <span className="sm_sub_title_semi">
                  {convertToDisplay(shipmentDetails?.freightCost)}
                </span>
              </div> */}
            </div>
            <Divider style={{ margin: "16px 0" }} />
            {loading ? (
              <SpinLoading />
            ) : (
              <>
                {isEmpty(containerList) ? (
                  <div className="w-full flex justify-center mt-6">
                    <NoData />
                  </div>
                ) : (
                  <Space className="sm-space-vertical" direction="vertical">
                    {containerList?.map((container: any) => {
                      const deliveryOrderNumbers = container?.deliveryOrderNumbers?.filter(
                        (it: string) => !!it,
                      );
                      return (
                        <div className="sm-container-list flex flex-wrap bg-[#fafafd]">
                          <ContainerInfo
                            title={
                              <>
                                <span>{t("Container #")} </span> <span></span>
                              </>
                            }
                            value={
                              <div className="container-id-wrapper">
                                <div className="sub-title sm_body_b2_semi">
                                  {container?.containerNumber}
                                </div>
                                <CopyButton message={container?.containerNumber} />
                              </div>
                            }
                          />
                          <ContainerInfo
                            title={<span>{t("Delivery Order #")}</span>}
                            value={
                              deliveryOrderNumbers?.length > 0 ? (
                                <div className="container-id-wrapper">
                                  <div
                                    className="sub-title sm_body_b2_semi"
                                    onClick={(ev: any) => {
                                      ev.stopPropagation();
                                      if ((deliveryOrderNumbers?.length as number) > 1) {
                                        setVisibleModalDeliveryOrder(true);
                                      }
                                      setContainerSelected(container);
                                    }}
                                  >
                                    {deliveryOrderNumbers?.[0]}
                                  </div>
                                  {(deliveryOrderNumbers.length as number) === 1 ? (
                                    <CopyButton message={deliveryOrderNumbers?.[0]} />
                                  ) : (
                                    <div
                                      className="sm_body_b3_reg text-gray800 bg-gray100 rounded-[4px] px-1 py-2 h-6 cursor-pointer hover:bg-gray200 flex items-center"
                                      onClick={(ev: any) => {
                                        ev.stopPropagation();
                                        setVisibleModalDeliveryOrder(true);
                                        setContainerSelected(container);
                                      }}
                                    >
                                      +{(deliveryOrderNumbers.length as number) - 1}
                                    </div>
                                  )}
                                </div>
                              ) : null
                            }
                          />
                          <ContainerInfo title={t("Freight Type")} value={container?.freightType} />
                          {/* </div>
                          <div className="flex w-full"> */}
                          <ContainerInfo
                            title={t("Gross Weight")}
                            value={
                              container?.grossWeight &&
                              `${convertToDisplay(container?.grossWeight)} ${(
                                container?.shipmentWeightUnitCode ?? container?.containerWeightUnit
                              )?.toLowerCase()}`
                            }
                          />
                          <ContainerInfo
                            title={t("Volume")}
                            value={
                              container?.volume &&
                              `${convertToDisplay(container?.volume, "decimalNumber")} m3`
                            }
                          />
                          <ContainerInfo
                            title={t("Items")}
                            value={
                              container?.quantity && `${convertToDisplay(container?.quantity)} `
                            }
                          />
                        </div>
                      );
                    })}
                  </Space>
                )}
              </>
            )}
          </>
        );
      })()}
    </WrapperSection>
  );
};

const ContainerInfo = ({ title = "", value }: { title: ReactNode; value: ReactNode }) => {
  return (
    <div className="header-item pb-[10px] flex flex-col w-1/2 tablet:w-1/3">
      <div className="title sm_body_b3_reg">{title}</div>
      <div className="sub-title sm_body_b2_semi">{value || DEFAULT_VALUES?.noData}</div>
    </div>
  );
};

export default ContainerListView;
