import { Modal } from "console-system-components";
import { EventDateTimeProps } from "interface/general";
import { useTranslation } from "react-i18next";
import { getEventDateTime, getVariance } from "scripts/helpers";
import { DeliveredEstimationAccuracyRow } from "./DeliveredNodeModal";

const InTransitNodeModal = ({
  isOpenInTransitEventModal,
  actualShipDate,
  setIsOpenInTransitEventModal,
  plannedShipDate,
}: any) => {
  const { t } = useTranslation();

  const items = [
    {
      left: {
        label: t("In-transit"),
        value: getEventDateTime(actualShipDate)?.datetime ?? "-",
      },
    },
    {
      left: {
        label: t("Estimated Ship"),
        value: getEventDateTime(plannedShipDate as EventDateTimeProps)?.datetime ?? "-",
      },
      right: {
        label: t("Variance"),
        value: getVariance(actualShipDate, plannedShipDate, t),
      },
    },
  ];

  return (
    <Modal
      visible={isOpenInTransitEventModal}
      title={t("Ship Estimation Accuracy")}
      className="delivered-estimation-accuracy-modal"
      onCancel={() => setIsOpenInTransitEventModal(false)}
      centered
      customFooter={null}
    >
      <div className="delivered-estimation-accuracy-modal__wrapper">
        {items?.map((item: any, index: number) => {
          return <DeliveredEstimationAccuracyRow key={index} data={item} />;
        })}
      </div>
    </Modal>
  );
};

export default InTransitNodeModal;
