import { Modal } from "console-system-components";
import { EventDateTimeProps } from "interface/general";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import { DATE_TIME_FORMAT_TO_DISPLAY } from "scripts/constants";
import { emptyFunction, ordinalSuffixOf } from "scripts/helpers";
import "./EstimateDateTimeModal.scss";

const EstimateDateTimeModal = ({
  code = "X2",
  visible = false,
  setVisible = emptyFunction,
  data,
}: {
  code?: "X2" | "B6" | "C1" | null;
  visible?: boolean;
  setVisible: any;
  data: EventDateTimeProps[];
}) => {
  const { t } = useTranslation();

  const getLabel = () => {
    switch (code) {
      case "X2":
        return t("Estimated Delivered");
      case "B6":
        return t("Estimated Arrival");
      case "C1":
        return t("Estimated Departure");
    }
  };
  const renderRow = (it: any, index: number) => {
    switch (code) {
      case "X2":
        return (
          <Trans
            defaults="{{index}} Estimated Delivered {{time}}"
            values={{
              index: ordinalSuffixOf(index + 1, t),
              time: moment.utc((it?.date + it?.time) * 1000)?.format(DATE_TIME_FORMAT_TO_DISPLAY),
            }}
          />
        );
      case "B6":
        return (
          <Trans
            defaults="{{index}} Estimated Arrival {{time}}"
            values={{
              index: ordinalSuffixOf(index + 1, t),
              time: moment.utc((it?.date + it?.time) * 1000)?.format(DATE_TIME_FORMAT_TO_DISPLAY),
            }}
          />
        );
      case "C1":
        return (
          <Trans
            defaults="{{index}} Estimated Departure {{time}}"
            values={{
              index: ordinalSuffixOf(index + 1, t),
              time: moment.utc((it?.date + it?.time) * 1000)?.format(DATE_TIME_FORMAT_TO_DISPLAY),
            }}
          />
        );

      default:
        break;
    }
  };

  return (
    <Modal
      visible={visible}
      title={`${getLabel()} (${data?.length})`}
      className="estimated-event-modal hide-footer-modal"
      onCancel={() => setVisible(false)}
      cancelText={t("Cancel")}
      okText={t("Apply")}
      centered
    >
      <div className="flex flex-col max-h-[500px] overflow-y-auto shipment-est-list">
        {data?.map((it: EventDateTimeProps, index: number) => (
          <div className="pb-2 last:pb-0 sm_body_b1_reg" key={index}>
            {renderRow(it, index)}
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default EstimateDateTimeModal;
